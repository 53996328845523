<template>
  <section
    class="cuckoo-payment"
  >
    <b-row>
      <b-col
        class="ml-2 mr-2"
      >
        <b-row>
          <b-col
            xs="12"
            md="7"
            class="mx-auto"
          >
            <b-card>
              <div class="mt-4">
                <h2
                  class="bold"
                >
                  Payment
                </h2>

                <b-form-group
                  label="Account name"
                  label-for="account-name"
                  class="mb-2"
                >
                  <b-form-input
                    class="input"
                    id="account-name"
                    placeholder="Account name"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>
                
                <b-form-group
                  label="Account number"
                  label-for="account-number"
                  class="mb-2"
                >
                  <b-form-input
                    class="input"
                    id="account-number"
                    placeholder="Account number"
                    v-model="accountNumber" 
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>
                
                <b-form-group
                  label="Sort code"
                  label-for="sort-code"
                  class="mb-2"
                >
                  <b-form-input
                    id="sort-code"
                    class="input"
                    placeholder="Sort code"
                    autofocus
                    v-model="branchCode" 
                    autocomplete="off"
                  />
                </b-form-group>

                <div>
                  <p>
                    By clicking “Join Cuckoo”, you:
                  </p>
                  <p>
                    Confirm you are over 18 years old; Confirm you're authorised to make Direct Debits on this account; 
                    agree to our <a href="https://www.cuckoo.co/legal/terms-and-conditions">Terms and Conditions</a>; 
                    and have read and understood our <a href="https://www.cuckoo.co/privacy-policy">Privacy Policy</a>, 
                    which tells you how we use your information to provide our services and how we'll communicate with you.
                  </p>
                </div>

                
                <b-button
                    block
                    class="mt-2 cuckoo-button"
                    :disabled=" branchCode === '' || accountNumber === '' "
                    v-on:click="validatedAccount" 
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                    v-if="loadingValidatedAccount"
                  ></span>
                  {{ btnText }}
                </b-button>
              </div>
            </b-card>
          </b-col>

          <b-col
            xs="12"
            md="5"
            class="mx-auto"
          >
            <b-card>
              <div
                class="information"
              >
                <div
                  class="pricing-badge text-left margin-buttom"
                >
                  <b-badge
                    variant="light-primary"
                    pill
                  >
                    KEY INFO
                  </b-badge>
                </div>

                <div>
                  <h2 
                    class="bold"
                  >
                    Your address
                  </h2>
                  <p class="pb-2" v-if="user">
                    <span v-if="user.moves">{{user.moves[0].moveDetails.moveToAddress}}</span>
                  </p>
                </div>

                <div v-if="cuckoo.perMonth">
                  <h2
                    class="bold"
                  >
                    {{cuckoo.perMonth}}
                  </h2>
                  <p class="">
                    We'll charge you if you make phone calls on your landline. Here's the full list of call charges.
                  </p>
                  <p class="pb-2">
                    There may be some circumstances where you'll need to pay more - for example, 
                    if a new line has to be installed and you miss an engineer visit from Openreach 
                    (you will be made aware if an engineer visit is needed). Here's a full list of other charges.
                  </p>
                </div>
                
                <div v-if="cuckoo.cost">
                  <h2
                    class="bold"
                  >
                    {{cuckoo.cost}}
                  </h2>
                  <p class="pb-2">
                    This will be deducted from your account within 2-5 working days via Direct Debit. 
                    This covers connection costs with Openreach, regardless of whether you need a new 
                    line, engineer or if we're taking over the existing line. You will need £60 in 
                    your account or the switch will be cancelled. We'll also send you a router, 
                    worth £82 RRP, in the post.
                  </p>
                </div>
                
                <div v-if="cuckoo.price">
                  <h2
                    class="bold"
                  >
                    {{cuckoo.price}}
                  </h2>
                  <p class="">
                    Whatever the next 12 months brings, a price rise for your broadband won't be one of them.
                  </p>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BBadge, BFormCheckbox, BFormDatepicker, BFormInput, BFormGroup, BCard, BButton
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axiosCustom from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BCard,
    BButton,
  },
  data() {
    return {
      user: {},
      cuckoo: {},
      disabled: true,
      branchCode: '',
      accountNumber: '',
      loadingValidatedAccount: false,
      btnText: 'Join cuckoo',
    }
  },
  created() {
    this.$http.get('/cuckoo/info').then(res => { this.cuckoo = res.data })
    const userData = getUserData()
    this.user = userData
  },
  methods: {
    validatedAccount: function(){
      this.disabled = true
      this.loadingValidatedAccount = true
      this.btnText = 'validating account';
      axiosCustom.post(`cuckoo/checkCard`,
        {
          branchCode: this.branchCode,
          accountNumber: this.accountNumber,
        }
      )
      .then( ({data}) => {
        if(data.checkBankAccount){
          this.$toast.success("Verified account", {
            style: {
              backgroundColor: '#63b463',
              color: '#fff'
            }
          })
        }
        else{
          this.$toast.warning("invalid account", {
            style: {
              backgroundColor: '#ecb90d',
              color: '#fff'
            }
          })
        }
      })
      .catch((error) => {
        this.$toast.error("Error processing request", {
          style: {
            backgroundColor: '#ff5252',
            color: '#fff'
          }
        })
      })
      .finally(() => {
        this.loadingValidatedAccount = false
        this.btnText = 'Join cuckoo';
      })
    }
  }
}
</script>

<style lang="scss">
  .cuckoo-payment{
    .information{
      background-color: rgb(255, 255, 255);
      padding: 1rem;
    }
    .input{
      border-radius: 30px;
    }
    .bold{
      font-weight: 700;
      color: #000 !important;
    }
    p, b, div{
      color: #000 !important;
    }
    .cuckoo-button{
      border-radius: 100px;
      background-color: rgb(5, 128, 229) !important;
      color: rgb(255, 255, 255) !important;
      padding: 20px 25px;
      text-decoration: none;
      font-weight: 700;
      border: none;
    }
    .validate{
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>